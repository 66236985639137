import {Link, useNavigate} from 'react-router-dom';
import React, {useState, useEffect, useCallback} from 'react';
import './Tableinput.css';
import AppButton from '../button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import {downloadExcel} from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import swal from 'sweetalert';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import { setLoading } from 'src/store/reducers/ui';

const CustomersTable = () => {
  let navigate = useNavigate();
  const [allCustomers, setallCustomers] = useState([]);
  const loading = useSelector((state: any) => state.ui.loading);
  const dispatch = useDispatch();

  let local: any = localStorage.getItem('userInfo');
  const permissionsArray = JSON.parse(local).permissions;

  const getAllCustomes = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: "get",
      url: 'customers/getCustomer'
    });

    if (result) {
      setLoading(false);
      let response = result.data
      setallCustomers(response);
      const tableData = response.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item.id,
          companyName: item.companyName,
          custFirstName: item.custFirstName,
          custLastName: item.custLastName,
          name: item.custFirstName + ' ' + item.custLastName,
          billAddress:item.billAddress,
          custEmail: item.custEmail,
          custContact: item.custContact,
          customer_ref_id: item.customer_ref_id,
          custaddress: item.custaddress,
          zipcode: item.zipcode,
          status: item.status == '1' ? 'Active' : 'In-Active',
          tax: item?.tax ? item?.tax : "-"
        };
      });

      setTimeout(() => {
        tableData && $('#example').DataTable({
          data: tableData,
          columns: [
            {data: 'index'},
            {data: 'companyName'},
            {data: 'name'},
            {data: 'custEmail'},
            {data: 'custContact'},
            {data: 'tax'},
            {data: 'customer_ref_id'},
            {data: 'custaddress'}, 
            {data: 'billAddress'}, 
            {data: 'zipcode'},
            {data: 'status'},
            {
              title: 'Action',
              render: function (data, type, row, meta) {

                const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                let editIcon = `<img src="/assets/edit.svg"
                data-toggle="tooltip"
                id="edit"
                data-html="true"
                data-bs-placement="top"
                title="Edit" height="600"
                >`;

                let actionIcons = `<div class='iconWapper'>   ${editIcon} ${deleteIcon} </div>`;
                return actionIcons;
              },

              searchable: false,
              orderable: false
            }
          ]
        });

        const table: any = document.querySelector('#example');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#example').DataTable();
          $('#example tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            navigate(`/admin/cutomers/update`,{state:data.id});
          });
          $('#example tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log("delete data: " + data)
            CustomerDeleteById(data?.id);
          });
        });
      }, 1000);
    }
  };

  const CustomerDeleteById = (id: number) => {
    // $('#example').DataTable().destroy();
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete customer ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then(async (response: any) => {
      if (response) {
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: "delete",
          url: `customers/delete/${id}`
        });
        if (result) {
          // swal({
          //   text: 'customer deleted successfully',
          //   icon: 'success'
          // });
          navigate(0)
        }
      }
    });
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);

    const rows = allCustomers?.map((item: any, index: any) => {
      return [
        index + 1,
        item.companyName,
        item.custFirstName + ' ' + item.custLastName,
        item.custEmail,
        item.custContact,
        item.customer_ref_id,
        item.custaddress,
        item.zipcode,
        item.status == '1' ? 'Active' : 'In-Active'
      ];
    });

    const headers = [
      [
        'S.No.',
        'Company Name',
        'Name',
        'Email',
        'Phone',
        'QuickBooks Ref Id',
        'Address',
        'Zipcode',
        'Status'
      ]
    ];

    let content = {
      startY: 60,
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(`${new Date().toLocaleDateString('en-GB')} Customers Table.pdf`);
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Company Name',
      'Name',
      'Email',
      'Phone',
      'QuickBooks Ref Id',
      'Address',
      'Zipcode',
      'Status'
    ];
    const rows: any = allCustomers?.map((item: any, index: any) => {
      return [
        index + 1,
        item.companyName,
        item.custFirstName + ' ' + item.custLastName,
        item.custEmail,
        item.custContact,
        item.customer_ref_id,
        item.custaddress,
        item.zipcode,
        item.status == '1' ? 'Active' : 'In-Active'
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString('en-GB')} Customers Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Customers Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };
  
  useEffect(() => {
      getAllCustomes();
  }, []);


  return (
    <>
      <div >
        <div
          className="d-flex justify-content-end"
        >
          <div className="btn-group">
            <div className="dropdown">
              <button
                className="btn btn-dark mr-3 btn-lg mb-3  dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Export
              </button>

              <div
                className="dropdown-menu ExportDropdown col-2 dropdownImg"
                aria-labelledby="dropdownMenuButton"
              >
                <div
                  className="dropdown-item d-flex flex-row"
                  onClick={() => {
                    ExcelGenerate();
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <img
                    src="/assets/excel.png"
                    className="mx-2"
                    style={{
                      height: '30px',
                      width: '30px',
                      margin: 0
                    }}
                  />
                  Excel
                </div>
                <div className="dropdown-divider"></div>
                <div
                  onClick={() => {
                    PdfGenerate();
                  }}
                  className="dropdown-item d-flex flex-row "
                  style={{cursor: 'pointer'}}
                >
                  <img
                    src="/assets/pdf.png"
                    className="mx-2"
                    style={{
                      height: '30px',
                      width: '30px',
                      margin: 0
                    }}
                  />
                  PDF
                </div>
              </div>
            </div>
          </div>
          {permissionsArray.map((data: any) => {
            return (
              <>
                {data.title === 'Customers' && data.edit === true && (
                  <div>
                    <AppButton
                      children={'Add New'}
                      className="btn-lg mb-3 "
                      onClick={() => {
                        navigate('/admin/customers/add');
                      }}
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>

      {loading ? (
        <div>
          <div className="d-flex justify-content-center">
            <ScaleLoader color="#007bff" height={40} />
          </div>
        </div>
      ) : (
        <>
         <table id="example" className="table table-hover table-responsive">
            <thead className="thead bg-primary">
              <tr>
                <th>S.No.</th>
                <th>Company Name</th>
                <th>Name </th>
                <th>Email</th>
                <th>Phone</th>
                <th>Tax</th>
                <th>QuickBooks Ref Id</th>
                <th>Ship Address</th>
                <th>Bill Address</th>
                <th>Zipcode</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </>
      )}
    </>
  );
};

export default CustomersTable;
