import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import '@fontsource/poppins';
import './login.css';
import swal from 'sweetalert';
import { progressLoader } from 'src/store/reducers/ui';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import { removeWindowClass } from 'src/utils/helpers';
import { loginUser } from 'src/store/reducers/auth';
import LoginInput from 'src/components/Inputs/LoginInputs/LoginInput';
import AppButton from 'src/components/button/Button';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disbale, setDisable] = useState(true);

  const [error, setError] = useState({
    email: '',
    password: ''
  });

  const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmpty = /^\s*$/;

  const loginAuth = async (email: string, password: string, e: any) => {
    e.preventDefault();

    if (isEmpty.test(password) == true) {
      setError({...error, password: 'Please Enter password'});
    }
    if (isEmpty.test(email) == true) {
      setError({...error, email: 'Email field Required'});
    }
    if (EmailRegex.test(email) == false && isEmpty.test(password) == true) {
      setError({...error, password: 'Please Enter Password'});
      setError({...error, email: 'Please Enter Email'});
    } else if (
      EmailRegex.test(email) == true &&
      isEmpty.test(password) == false
    ) {
      // check the min values
      if (!!password && password.length <= 5) {
        await swal({
          title: 'validation Error',
          text: "Password length should be at least six characters.",
          icon: 'error',
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true
        });
        
        return 
      }

      dispatch(progressLoader(20));
      setDisable(true);
      setError({...error, password: '', email: ''});

      const result = await apiResponseGenerator({
        method: 'post',
        url: 'auth/login',
        body: {
          email,
          password
        },
        dispatchFunction: dispatch
      });

      if (!result) {
        setDisable(false);
        return;
      }
      removeWindowClass('login-page');
      removeWindowClass('hold-transition');
      dispatch(loginUser(result.data));
      toast.success('Login Successful');
      navigate('/admin');
      setDisable(false);
    }
  };

  useEffect(() => {
    if (EmailRegex.test(email) == false && email.length > 3) {
      setError({...error, email: 'Please Enter a valid email '});
      setDisable(true);
    } else {
      setError({...error, email: ''});
      setDisable(false);
    }
  }, [email]);

  return (
    <form
      className="container-fluid bgImg"
      onSubmit={(e) => {
        loginAuth(email, password, e);
      }}
    >
      <div className="login-box">
        <div className="card" style={{width: '100%'}}>
          <div className="wrapper center">
            <img src="/assets/logo.svg" />
            <p className="fw-bolder text-center mt-1 logoText">
              Sales & inventory Management
            </p>
          </div>
          <div className="card-body">
            <div style={{maxWidth: '450px', margin: 'auto'}}>
              <LoginInput
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                label={'Email'}
                placeholder={'Enter Your Email'}
                icon={'fa fa-envelope icon'}
                type={'email'}
                errorMsg={error.email}
              />

              <LoginInput
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                label={'Password'}
                placeholder={'Enter Your Password'}
                icon={'fa fa-key icon'}
                type={'password'}
                errorMsg={error.password}
              />

              <Link to="/forgot-password">
                <p className="font-weight-light text-end forgot">
                  Forgot Password ?
                </p>
              </Link>
              <AppButton
                type="submit"
                disabled={disbale}
                children={'Login'}
                className="btn btn-dark col-12"
                style={{backgroundColor: 'black'}}
                onClick={(e) => {
                  loginAuth(email, password, e);
                }}
              />
              <span>
                <h6 className="d-flex justify-content-center align-top text-muted text-sm mt-2 ">
                  Designed & Developed by
                </h6>
                <b className="d-flex justify-content-center align-bottom text-sm fs-5">
                  Zetsol Technologies Pvt Ltd.
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
