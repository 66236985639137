
import React from 'react';
import UserTable from 'src/components/Tables/userTable';

const Users = () => {
  
  return (
    <div>
        <UserTable />
    </div>
  );
};

export default Users;
