import React, {useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';
import {useDispatch} from 'react-redux';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import { setLoading } from 'src/store/reducers/ui';
import SmallBox from 'src/components/small-box/SmallBox';
import { ContentHeader } from 'src/components';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [SalesData , setSalesData] = useState<any>([])
  const [SalesDate , setSalesDate] = useState<any>([])
  let local: any = localStorage.getItem('userInfo');
  const permissionsArray = JSON.parse(local).permissions;

  useEffect(() => {
    getDashboardCount();
    getChartsData()
  }, []);

  const getDashboardCount = async () => {
    const result = await apiResponseGenerator({dispatchFunction: dispatch, method: 'get', url: 'dashboard/get'});
    if (result) {
      setLoading(false);
      setTotalCustomers(result.data.totalCustomers);
      setTotalSales(result.data.totalSales);
      setTotalProducts(result.data.totalProductFlavors);
    }
  };

  const getChartsData = async () => {
    const result = await apiResponseGenerator({dispatchFunction: dispatch, method: 'get', url: 'dashboard/salesTrend'});
    if (result) {
      setLoading(false);
      const response = result?.data
      
      const salesArr = response.sales.map((item:any, index:number) =>{
        return item.sales
      })
      setSalesData(salesArr)

      const SalesDateArr = response.sales.map((item:any, index:number) =>{
        return item.Salesdate
      })
      setSalesDate(SalesDateArr)
      
    }
  };

  const options = {
    grid: {top: 8, right: 8, bottom: 24, left: 60},
    xAxis: {
      type: 'category',
      data: SalesDate
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: SalesData,
        type: 'line',
        color: 'green',
        bold: 10,
        smooth: true
      }
    ],
    tooltip: {
      trigger: 'axis'
    }
  };

  return (
    <div className="container-flu/id">
      <ContentHeader title="Dashboard" />

      <div className="row m-2">
        <div className="col-lg-4 col-6">
          <SmallBox
            type="success"
            icon="ion-stats-bars"
            count={totalProducts}
            title="TOTAL FlAVORS"
            navigateTo={permissionsArray.some(({ title, view }:any) => title === "Products" && view)  ? "/admin/products/flavors" : "" }
          />
        </div>

        <div className="col-lg-4 col-6">
          <SmallBox
            type="warning"
            icon="ion-stats-bars"
            count={totalCustomers}
            title="TOTAL CUSTOMERS"
            navigateTo={permissionsArray.some(({ title, view }:any) => title === "Customers" && view) ? "/admin/customers" : "" }
          />
        </div>

        <div className="col-lg-4 col-6">
          <SmallBox
            icon="ion-stats-bars"
            type="danger"
            count={totalSales}
            title="TOTAL SALES"
            navigateTo={permissionsArray.some(({ title, view }:any) => title === "Sales & Invoices" && view )? "/admin/sales-and-invoices/invoice" : "" }

          />
        </div>
      </div>
      <br />
      <div className="bg-white p-3 rounded m-3">
        <h4 className="font-weight-bold my-3">Months to date sales</h4>
        <ReactECharts option={options} theme={'black'} />
      </div>
    </div>
  );
};

export default Dashboard;
