import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './model.css';
import Select from 'react-select';
import {Table} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import swal from 'sweetalert';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';

export default function FreeCases({onHide, onSubmit, PreviousCases}: any) {
  const ExistingFreeCases = PreviousCases &&
    PreviousCases?.flatMap((data: any, index: number) => {
      if (data.free && data.free.length > 0) {
        return data.free.map((item: any, index: number) => {
          return {
            flavourCategory: {
              id: item.product_id,
              value: item.product_id,
              label: item.productName
            },
            flavour: {
              id: item.product_flavour_id,
              value: item.product_flavour_id,
              label: `${item.flavourName}(${item.ShortCode})`
            },
            generatedStock:
              item?.current_stock -
              (item?.total_qty_free - item?.total_qty_generated) +
              item.qty,
            quantity: item.qty
          };
        });
      }else {
        return []; 
      }
    });
  // hardCoded Values
  const flavourFldName = {
    flavourCategory: 'flavourCategory',
    flavour: 'flavour'
  };

  const default_FlData = [
    {
      flavourCategory: null,
      flavour: null,
      generatedStock: null,
      quantity: null
    }
  ];

  const [FlData, setFlData] = useState(
    ExistingFreeCases.length > 0 ? ExistingFreeCases : default_FlData
  );
  const [products, setProducts] = useState([]);
  const [deleted_array, setDeleted_array]: any = useState([]);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    FlData.some((data: any) => {
      if (data?.flavourCategory && data?.flavour && data?.quantity > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });
  }, [FlData]);

  const getProducts = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'products/getAll'
    });
    if (result) {
      const product_array = result.data;
      product_array.map((element: any) => {
        return (
          (element['label'] = element['productName']),
          delete element['productName'],
          (element['value'] = element['id'])
        );
      });
      setProducts(product_array);
    }
  };

  const HandleSubmit = async () => {
    let reposnse = {
      FlData,
      deleted_array
    };
    await onSubmit(reposnse);
  };

  const add_row = () => {
    setFlData((item: any) => [
      ...item,
      {
        flavourCategory: null,
        flavour: null,
        generatedStock: null,
        quantity: null
      }
    ]);
  };

  const CheckSameDataExits = (fldName: string, selectedOption: any) => {
    let FlData_copied: any[] = FlData;
    let isExits: boolean = false;

    if (FlData?.length > 1) {
      FlData_copied.forEach((element: any) => {
        if (+element[fldName]?.id === +selectedOption?.id) {
          isExits = true;
        }
      });

      if (isExits) {
        swal({
          title: 'Error',
          text: `${selectedOption.label} already exists.`,
          icon: 'warning',
          buttons: [false, true],
          dangerMode: true,
          closeOnClickOutside: false
        });
      }
    }

    return isExits;
  };

  const getStock = async (id: number) => {
    let obj = {};
    if (!id) {
      return;
    }
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `productflavors/getFlavorStock/${id}`
    });
    if (result) {
      const res = result.data;
      obj = {
        generatedStock: res.generatedStock
      };
      // res.forEach((item: any) => {
      // });
      return obj;
    }
  };

  const handleChange = async (name: string, selectFlavor: any, index: any) => {
    let setValue: any = {
      id: null,
      value: null,
      label: null
    };
    let checkStock: any;
    let flavorsOptions: any;

    if (name === flavourFldName.flavourCategory) {
      flavorsOptions = await getFlavorByProductId(selectFlavor.id);
    }
    if (name === flavourFldName.flavour) {
      const checkData = CheckSameDataExits(
        flavourFldName.flavour,
        selectFlavor
      );
      if (checkData) return;
      checkStock = await getStock(+selectFlavor.id);
    }

    setValue = {
      id: +selectFlavor.id,
      value: +selectFlavor.value,
      label: selectFlavor.label
    };

    const updated_fl: any = FlData.map((item: any, RowIndex: number) => {
      const objKeys = Object.keys(item);
      const condition = index === RowIndex && objKeys.includes(name);
      let returnValue = null;

      if (condition) {
        returnValue = checkStock
          ? {
              ...item,
              [name]: setValue,
              generatedStock: checkStock.generatedStock
            }
          : {...item, flavorsOptions: flavorsOptions, [name]: setValue};
      } else {
        returnValue = item;
      }
      if (returnValue) return returnValue;
    });
    console.log(updated_fl, 'updated_fl');
    setFlData(updated_fl);

    return 'success';
  };

  const onChangeQuantity = (e: any, index: any) => {
    const {name, value} = e.target;
    const valueToNumber = value;
    const updated_fl: any = FlData.map((item: any, RowIndex: any) => {
      const objKeys = Object.keys(item);
      return index === RowIndex &&
        objKeys.includes(name) &&
        valueToNumber <= item.generatedStock
        ? {...item, [name]: valueToNumber}
        : item;
    });
    setFlData(updated_fl);
  };

  const getFlavorByProductId = async (id: number) => {
    if (!id) return;
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `productflavors/getFlavor/${id}`
    });
    if (result) {
      const flavor_array = result.data;
      flavor_array.map((element: any) => {
        return (
          (element['label'] = element['FlavorName']),
          delete element['FlavorName'],
          (element['value'] = element['id'])
        );
      });
      return flavor_array;
    }
  };

  const remove_row = async (
    index: any,
    product_id?: number,
    flavour_id?: number
  ) => {
    const ExistingCasesChecker = ExistingFreeCases.find(
      (data: any) =>
        data.flavourCategory.id === product_id && data.flavour.id === flavour_id
    );
    if (ExistingCasesChecker) {
      setDeleted_array((item: any) => [
        ...item,
        {
          flavor_id: flavour_id
        }
      ]);
    }
    const newArr = [...FlData];
    newArr.splice(index, 1);
    setFlData(newArr);
  };

  return (
    <>
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title id="contained-modal-title-vcenter">
            Add Free Cases
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-3">
          <div className="table-responsive-xxl" style={{overflowX: 'clip'}}>
            <Table className="table table-bordered bg-light my-2">
              <thead className="bg-light">
                <tr className="text-start" style={{height: 60}}>
                  <th>Product Category</th>
                  <th>Flavor</th>
                  <th>GRN Stock</th>
                  <th>Quantity</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {FlData?.map((items: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td
                        className=" bg-white"
                        style={{height: '60px', width: '250px'}}
                      >
                        <Select
                          value={items?.flavourCategory}
                          name="flavourCategory"
                          onChange={(e: any) =>
                            handleChange(
                              flavourFldName.flavourCategory,
                              e,
                              index
                            )
                          }
                          options={products}
                          placeholder="Select Product Category"
                        />
                      </td>

                      <td
                        className=" bg-white"
                        style={{height: '60px', width: '220px'}}
                      >
                        <Select
                          value={items?.flavour}
                          name="flavour"
                          isDisabled={
                            items?.flavorsOptions?.length >= 0 ||
                            items?.flavorsOptions
                              ? false
                              : true
                          }
                          onChange={(e: any) =>
                            handleChange(flavourFldName.flavour, e, index)
                          }
                          options={items?.flavorsOptions}
                          placeholder="Select Flavor"
                        />
                      </td>

                      <td
                        className="main_Td bg-white"
                        style={{
                          height: '60px',
                          textAlign: 'center',
                          width: '120px'
                        }}
                      >
                        <input
                          value={
                            items?.generatedStock
                              ? items?.generatedStock - items.quantity
                              : 0
                          }
                          disabled={true}
                          style={{textAlign: 'center'}}
                          className="form-control border-0 bg-transparent main_input hideNumber_controls"
                        />
                      </td>

                      <td
                        className="main_Td bg-white"
                        style={{
                          height: '60px',
                          textAlign: 'center',
                          width: '120px'
                        }}
                      >
                        <input
                          name="quantity"
                          required
                          style={{textAlign: 'center'}}
                          type="number"
                          value={items?.quantity ? items.quantity : ''}
                          onChange={(e: any) => onChangeQuantity(e, index)}
                          placeholder="0"
                          className="form-control border-0 bg-transparent main_input hideNumber_controls"
                        />
                      </td>

                      <td
                        className="main_Td bg-white"
                        style={{height: '60px', width: '100px'}}
                      >
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={() => {
                            remove_row(
                              index,
                              items?.flavourCategory?.id,
                              items?.flavour?.id
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-dash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="my-3 d-flex justify-content-end">
            <button
              onClick={() => {
                add_row();
              }}
              type="button"
              className="btn btn-primary"
            >
              Add New
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-light" onClick={onHide}>
            Close
          </Button>
          <Button
            onClick={() => {
              HandleSubmit();
            }}
            disabled={disabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
