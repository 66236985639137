import {Link, useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import './Tableinput.css';
import AppButton from '../button/Button';
import swal from 'sweetalert';
import {useDispatch} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import ContentHeader from '../content-header/ContentHeader';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import {downloadExcel} from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import moment from 'moment';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';

const UsersTable = () => {
  let navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [allUsers, setallUsers]: any = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'user/getAll'
    });
    if (result) {
      setLoading(false);
      let response = result.data;
      setallUsers(response);
      const tableData = response.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item.id,
          name: item.firstName + ' ' + item.lastName,
          email: item.email,
          phoneNo: item.phoneNo ? item.phoneNo : '-',
          roleName: item?.role?.roleName,
          created_at: moment(item.created_at).format('MM/DD/YYYY'),
        };
      });

      setTimeout(() => {
        tableData && $('#example').DataTable({
          data: tableData,
          columns: [
            {data: 'index'},
            {data: 'name'},
            {data: 'email'},
            {data: 'phoneNo'},
            {data: 'roleName'},
            {data: 'created_at'},

            {
              title: 'Action',
              render: function () {
                return `
                <div class='iconWapper'>

                  <img src="/assets/edit.svg"
                  data-toggle="tooltip"
                  id="edit"
                  data-html="true"
                  data-bs-placement="top"
                  title="Edit" height="600"
                  >
                  
                  <img src="/assets/delete.svg"
                  data-toggle="tooltip"
                  data-html="true"
                  data-bs-placement="top"
                  id="delete"
                  title="Delete" height="600"
                  >

                </div>`;
              },

              searchable: false,
              orderable: false
            }
          ]
        });

        const table: any = document.querySelector('#example');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#example').DataTable();
          $('#example tbody').on('click', '#edit', function () {
            var data = table.row($(this).parents('tr')).data();
            navigate(`/admin/user-and-role/users/edit`, {state: data.id});
          });
          $('#example tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            console.log('delete data: ' + data);
            deleteUserById(data?.id);
          });
        });
      }, 1000);
    }
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Name',
      'Email',
      'Phone',
      'Roles',
      'Created at'
    ];
    const rows: any = allUsers?.map((item: any, index: any) => {
      return [
        index + 1,
        item.firstName + ' ' + item.lastName,
        item.email,
        item.phoneNo,
        item?.role?.roleName,
        moment(item.created_at).format('MM/DD/YYYY'),
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString('en-GB')} Users Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Users Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);

    const rows = allUsers?.map((item: any, index: any) => {
      return [
        index + 1,
        item.firstName + ' ' + item.lastName,
        item.email,
        item.phoneNo,
        item?.role?.roleName,
        moment(item.created_at).format('MM/DD/YYYY'),
      ];
    });

    const headers = [['S.No.', 'Name', 'Email', 'Phone', 'Roles', 'Created at']];
    let content = {
      startY: 60,
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(`${new Date().toLocaleDateString('en-GB')} Users Table.pdf`);
  };

  const deleteUserById = (id: number) => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete user ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then(async (response: any) => {
      if (response) {
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'delete',
          url: `user/delete/${id}`
        });
        if (result) {
          navigate(0);
        }
      }
    });
  };

  return (
    <>
      <ContentHeader title="Users" />
      <section className=" bg-white p-4 rounded m-3">
        <div>
          <div
            className="d-flex justify-content-end"
          >
            <div className="btn-group">
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-lg mb-3 mr-3 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export
                </button>

                <div
                  className="dropdown-menu ExportDropdown col-2 dropdownImg"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="dropdown-item d-flex flex-row"
                    onClick={() => {
                      ExcelGenerate();
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/excel.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    Excel
                  </div>
                  <div className="dropdown-divider"></div>
                  <div
                    onClick={() => {
                      PdfGenerate();
                    }}
                    className="dropdown-item d-flex flex-row "
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/pdf.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    PDF
                  </div>
                </div>
              </div>
            </div>
            <AppButton
              children={'Add New'}
              className="btn-lg mb-3 "
              onClick={() => {
                navigate('/admin/user-and-role/users/add');
              }}
            />
          </div>
        </div>

        {Loading ? (
          <div>
            <div className="d-flex justify-content-center">
              <ScaleLoader color="#007bff" height={40} />
            </div>
          </div>
        ) : (
          <>
            <table id="example" className="table table-hover">
              <thead className="thead bg-primary">
                <tr>
                  <th>S.No.</th>
                  <th>Name </th>
                  <th>Email </th>
                  <th>Phone </th>
                  <th>Roles</th>
                  <th>Created at</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
          </>
        )}
      </section>
    </>
  );
};

export default UsersTable;
