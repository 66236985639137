import React, {useEffect, useState} from 'react';
import PoshInputs from './Inputs';
import './input.css';
// import ContentHeader from '@app/components/content-header/ContentHeader';
// import ReceivingTable from '@app/components/Tables/ReceivingTable';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
// import {progressLoader} from '@app/store/reducers/ui';
import axios from 'axios';
import { progressLoader } from 'src/store/reducers/ui';
import { BASE_URL } from 'src/components/constants/app';
import ContentHeader from 'src/components/content-header/ContentHeader';
import ReceivingTable from 'src/components/Tables/ReceivingTable';
// import {BASE_URL} from '@app/components/constants/app';

export const NewShipments = () => {
  const state = useSelector((state: any) => {
    return state.auth?.token;
  });
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [agent, setagent] = useState();
  const [TrackNo, setTrackNo] = useState('');
  const [lotNo, setlotNo] = useState('');
  const [Weight, setWeight] = useState('');
  const [DispatchDate, setDispatchDate] = useState('');
  const [DutyCharges, setDutyCharges] = useState('');
  const [Freight, setFreight] = useState('');
  const [AgentId, setAgentId] = useState('');
  const [ExpectedDate, setExpectedDate] = useState('');
  const [AirwayNo, setAirwayNo] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let navigate: any = useNavigate();

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = async () => {
    try {
      dispatch(progressLoader(15));
      const res = await axios({
        url: `${BASE_URL}agents/getActiveAgents`,
        headers: config,
        timeout: 1000 * 5,
        onUploadProgress: (progressEvent: any) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        setLoading(false);
        const temp = res.data.data;
        temp.map((element: any) => {
          return (
            (element['label'] =
              element['firstName'] + ' ' + element['lastName']),
            delete element['firstName'] + ' ' + element['lastName'],
            (element['value'] = element['id'])
          );
        });
        setagent(temp);
      }
    } catch (error) {
      console.log(error);
      dispatch(progressLoader(100));
      setLoading(true);
    }
  };

  const options = agent;

  return (
    <div className="container-fluid ">
      <ContentHeader title="New Shipment" />
      <section className=" bg-white p-4 rounded mb-4 m-3">
        <div className="inputWrapper">
          <PoshInputs
            title="Lot No."
            validate={'*'}
            value={lotNo}
            onChange={(e: any) => {
              setlotNo(e.target.value);
            }}
            type={'text'}
            readOnly={true}
          />
          <PoshInputs
            validate={'*'}
            title="Airway Bill No."
            value={AirwayNo}
            onChange={(e: any) => {
              setAirwayNo(e.target.value);
            }}
            type={'text'}
            readOnly={true}
          />
        </div>

        <div className="inputWrapper">
          <PoshInputs
            validate={'*'}
            title="Weight"
            value={Weight}
            onChange={(e: any) => {
              setWeight(e.target.value);
            }}
            type={'text'}
            readOnly={true}
          />
          <div className="col-md-6" style={{marginTop: '-2px'}}>
            <label htmlFor="validationCustom03" className="form-label mb-1 d-flex">
              Shipping agent<span style={{color:"red", marginTop:"1px"}}>*</span>
            </label>
            <Select
              value={selectedOption}
              onChange={(e: any) => {
                setSelectedOption(e); 
              }}
              options={options}
              placeholder="Select shipping agent"
            />
          </div>
        </div>

        <div className="inputWrapper">
          <PoshInputs
          validate={'*'}
            title="Dispatched date"
            value={DispatchDate}
            onChange={(e: any) => {
              setDispatchDate(e.target.value);
            }}
            type={'date'}
            readOnly={true}
          />
          <PoshInputs
          validate={'*'}
            title="Expected delivery date"
            value={ExpectedDate}
            onChange={(e: any) => {
              setExpectedDate(e.target.value);
            }}
            type={'date'}
            readOnly={true}
          />
        </div>

        <div className="inputWrapper">
          <PoshInputs
            title="Duty charges"
            hideControl
            value={DutyCharges}
            onChange={(e: any) => {
              setDutyCharges(e.target.value);
            }}
            type={'number'}
            readOnly={true}
          />
          <PoshInputs
            title="Freight charges"
            value={Freight}
            hideControl
            onChange={(e: any) => {
              setFreight(e.target.value);
            }}
            type={'number'}
            readOnly={true}
          />
        </div>
      </section>

      <section className=" bg-white p-4 rounded m-3">
        <ReceivingTable
          dispatchedDate={DispatchDate}
          shipping_Agent_id={+selectedOption?.id}
          weight={Weight}
          airwayNo={AirwayNo}
          lotNo={lotNo}
          expectedDate={ExpectedDate}
          dutyCharges={+DutyCharges}
          freightCharges={+Freight}
        />
      </section>
    </div>
  );
};
