import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import AppButton from 'src/components/button/Button';
import SearchInput from 'src/components/Inputs/SearchInput/SearchInput';
import ContentHeader from 'src/components/content-header/ContentHeader';

function ExistingTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param: any = useParams();
  const [data, setdata] = useState([]);
  const [Previous, setPrevious]: any = useState();
  const [next, setNext]: any = useState();
  const [Pages, setPages]: any = useState([]);
  const [Total, setTotal] = useState<any>(0);
  const [From, setFrom] = useState<any>(0);
  const [To, setTo] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [Loading, setLoading] = useState(true);
  const [FilterBtn, setFilterBtn] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [search, setSearch] = useState<any>('');
  const [Active, setActive]:any = useState()

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getCustomerIncentiveById(param?.id);
  }, []);

  const getCustomerIncentiveById = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `invoice/getCustomerInvoices/${id}`
    });
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setdata(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink:any) => {
        if(activelink.active == true) {
          return setActive(activelink.label)
         }
      })
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  const nextPage = async (url: string, e: any) => {
    let pagesUrl = url.slice(41);
    const result = await apiResponseGenerator({dispatchFunction: dispatch,method: 'get',url: `${pagesUrl}&search=${search}`});
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setdata(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink:any) => {
        if(activelink.active == true) {
          return setActive(activelink.label)
         }
      })
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  const dateFilter = async () => {
    const result = await apiResponseGenerator({dispatchFunction: dispatch,method: 'get',url: `invoice/getCustomerInvoices/${param?.id}?to=${startDate}&from=${endDate}`});
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setdata(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };


  useEffect(()=>{
    if(search.length>1){
      SearchExisting(search)
    }
    if(search.length==0){
      getCustomerIncentiveById(param?.id)
    }
  },[search])

  const SearchExisting = async (SearchValue: string) => {
    const result = await apiResponseGenerator({dispatchFunction: dispatch,method: 'get',url: `invoice/getCustomerInvoices/${param?.id}?search=${SearchValue}`});

    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setdata(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink:any) => {
        if(activelink.active == true) {
          return setActive(activelink.label)
         }
      })
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  if (Loading) {
    return (
      <div>
        <div
          className="d-flex justify-content-center"
          style={{marginTop: '20pc', alignItems: 'center'}}
        >
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <>
      <ContentHeader title="Existing Invoice" />
      <section className=" bg-white p-4 rounded m-3">
        <div className="row mb-2 d-flex  col-lg-justify-content-around">

          <div id="searchInput" className="col-md-4">
            <SearchInput placeholder="Invoice #"
            value={search}
            onChange={(e:any)=>{setSearch(e.target.value)}} 
            />
          </div>

          <div className="col-md-3">
            <div className="input-group input-group-lg mb-3">
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                className="form-control"
                aria-label="From"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input-group input-group-lg mb-3">
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="form-control"
                aria-label="From"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>
          </div>

          <div className="col-md-2 d-flex justify-content-end">
            <AppButton
              children={'Apply Filter'}
              className="btn-lg mb-3  btn btn-secondary"
              disabled={FilterBtn}
              onClick={() => {
                dateFilter();
              }}
            />
          </div>
        </div>
        <div className="table-responsive-xxl">
          <table className="table table-hover">
            <thead className="thead- bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Company Name</th>
                <th scope="col">Invoice #</th>
                <th scope="col">Allocation Date</th>
                <th scope="col">Total Cases</th>
                <th scope="col">Total price</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: any) => {
                if (currentPage > 1) {
                  index = (currentPage - 1) * 10 + index + 1;
                } else {
                  index = index + 1;
                }

                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>
                      {item.companyName || "-"} 
                    </td>
                    <td>{item.id}</td>
                    <td>
                      {new Date(item.created_at).toLocaleDateString('en-GB')}
                    </td>
                    <td>{item.total_cases}</td>
                    <td>{item.grand_total}</td>
                    <td>{item.status}</td>
                    <td className="iconWapper">
                      <Link state={item.id} to={'/admin/productsinfo/flavors'}>
                        <img
                          src="/assets/eye.svg"
                          data-toggle="tooltip"
                          data-html="true"
                          data-bs-placement="top"
                          title="View"
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {Total > 10 ? (
          <nav
            aria-label="Page navigation example"
            className="d-flex justify-content-between"
            style={{marginTop: 10}}
          >
            <div>
              <p>
                Showing {From} to {To} of <b>{Total}</b> Existing Invoices
              </p>
            </div>
            <ul className="pagination  justify-content-end">
              <li className="page-item">
                <button
                  disabled={Previous?.url == null ? true : false}
                  className="page-link page-item"
                  onClick={(e: any) => {
                    nextPage(Previous.url, e);
                  }}
                >
                  Previous
                </button>
              </li>

              {Pages?.map((item: any, index: number) => {
                let temp = (index += 1);
                let url = item.url;
                return (
                  <li
                    onClick={(e) => nextPage(url, e)}
                    key={index}
                    className={`page-item ${+Active === temp ? "active" : ""}`}
                  >
                    <a className="page-link" href="#">{temp}</a>
                  </li>
                );
              })}

              <li className="page-item">
                <button
                  disabled={next?.url != null ? false : true}
                  onClick={(e) => {
                    nextPage(next.url, e);
                  }}
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
          ) : (
              ' '
            )} 
          <div className="d-flex justify-content-end ">
            <AppButton children={'Back'} onClick={() => navigate(-1)} />
          </div>
        </div>
      </section>
    </>
  );
}

export default ExistingTable;
