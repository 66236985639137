import React,{useState} from 'react';
import {ContentHeader} from 'src/components';
import ItemsTable from 'src/components/Tables/ItemsTable';

const Items = () => {
  return (
    <div>
      <ContentHeader title="Product Categories" />
      <ItemsTable/>
    </div>
  );
};

export default Items;
