import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './model.css';

export default function IncentiveModal(props?: any) {
  
  let quantityTotal:any;
  let productTotal:any; 
  let sumAmount:any; 

  return (
    <>
      {props?.data ? (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="bg-primary">
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-3">
            <table
              className="table table-hover "
              style={{border: '1px solid #dee2e6'}}
            >
              <thead className="thead bg-light">
                <tr>
                  <th scope="col">Product category</th>
                  <th scope="col">Flavor</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {props.data?.map((items:any , index:number)=>{
                  let sumWithInitial = props.data?.reduce(
                    (previousValue:any, currentValue:any) => +previousValue + +currentValue.quantity,
                    0
                  );
                  quantityTotal = sumWithInitial
                  return <>
                    <tr key={index}>
                      <td>{items?.flavourCategory?.label}</td>
                      <td>{items?.flavour?.label}</td>
                      <td>{items.BarCode ? items.BarCode : 0 }</td>
                      <td>{items.quantity}</td>
                    </tr>
                    </>
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td>Total Quantity</td>
                  <td>{quantityTotal!}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Total Products</td>
                  <td>{props.data.length}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button  className="btn btn-light"  onClick={props.onHide}>
              Close
            </Button>
            <Button disabled={props.GenerateBtnDisable} onClick={props.AddShipments}>Generate</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="bg-primary">
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-3">
            <table
              className="table table-hover "
              style={{border: '1px solid #dee2e6'}}
            >
              <thead className="thead bg-light">
                <tr>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Invoice#</th>
                </tr>
              </thead>
              <tbody>
                {props?.modelarray?.map((item:any,index:number)=>{
                    let TotalAmount = props?.modelarray?.reduce(
                      (previousValue:any, currentValue:any) => +previousValue + +currentValue.TotalAmount,
                      0
                    );
                    sumAmount = TotalAmount
                  return<> 
                  <tr key={index}>
                    <td >{item.CustomerFName +" "+item.CustomerLName}</td>
                    <td >{item.id}</td>
                  </tr>
                  </>
                })}
                  <tr>
                    <td></td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <span>Total Invoices Amount: </span>
                        <span className='ml-3'>{sumAmount}</span>
                      </div>
                    </td>
                  </tr>

              </tbody>
            </table>
            <br />
            <br />
            <br />
          </Modal.Body>
          {/* onHide={() => setModalShow(false)} */}
          <Modal.Footer>
            <Button className="btn btn-light" onClick={props.onHide}>
              Close
            </Button>
            <Button onClick={props.giveIncentive}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

