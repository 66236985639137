import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import './AddUsers.css';
import swal from 'sweetalert';
import Select from 'react-select';
import {useDispatch} from 'react-redux';
import { setLoading } from 'src/store/reducers/ui';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';

function AddUsers() {
  let navigate = useNavigate();
  const [FirstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [Disable, setDisabled] = useState(true);
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [Roles, setRoles] = useState([]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const UserRole_data = {
    FirstName,
    lastName,
    Email,
    Phone,
    Username,
    Password
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (
      Password === ConfirmPassword &&
      FirstName !== '' &&
      lastName !== '' &&
      emailRegex.test(Email) &&
      Password !== ''
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [UserRole_data]);

  const getRoles = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'roles/getAll'
    });
    if (result) {
      const temp = result?.data;
      let response = temp?.map((element: any) => {
        return (
          (element['label'] = element['roleName']),
          delete element['roleName'],
          (element['value'] = element['id'])
        );
      });
      setRoles(temp);
    }
  };

  const options = Roles;

  const Addusers = async () => {
    if (ConfirmPassword === Password) {
      if (
        (!!Password && Password.length <= 5) ||
        (!!ConfirmPassword && ConfirmPassword.length <= 5)
      ) {
        await swal({
          title: 'validation Error',
          text: 'Password length should be at least six characters.',
          icon: 'error',
          closeOnClickOutside: false,
          closeOnEsc: false,
          dangerMode: true
        });

        return;
      }


      const model = await swal({
        title: 'Are you sure ?',
        text: 'You want to add user ?',
        icon: 'warning',
        buttons: [true, true],
        dangerMode: true,
        closeOnClickOutside: false
      });

      if (!model) return;

      const data = {
        firstName: FirstName,
        lastName: lastName,
        email: Email,
        password: Password,
        role_id: selectedOption?.id,
        phoneNo: Phone,
        username: Username
      };

      const add_api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: 'user/add',
        body: data
      });
      if (add_api_response) {
        dispatch(setLoading(false));
        swal({
          title: 'Success',
          text: 'User successfully added.',
          icon: 'success'
        });
        navigate('/admin/user-and-role/users/');
      }
    }
  };

  return (
    <div>
      <ContentHeader title="Add User" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <PoshInputs
              title="First Name"
              onChange={(e: any) => {
                setFirstName(e.target.value);
              }}
              type={'text'}
              readOnly={true}
              validate={'*'}
            />
            <PoshInputs
              title="Last Name"
              onChange={(e: any) => {
                setlastName(e.target.value);
              }}
              type={'text'}
              readOnly={true}
              validate={'*'}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              title="Email"
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              type={'email'}
              readOnly={true}
              validate={'*'}
            />
            <PoshInputs
              title="Phone"
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
              type={'number'}
              hideControl
              readOnly={true}
              validate={'*'}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              title="Username"
              type={'text'}
              onChange={(e: any) => {
                setUsername(e.target.value);
              }}
              readOnly={true}
              validate={'*'}
            />

            <div className="col-md-6 form-group" style={{marginTop:"-7px; "}}>
              <label htmlFor="validationCustom03" className="form-label d-flex">
                User Role
                <span style={{color: 'red', marginTop: '-1px'}}>*</span>
              </label>
              <Select
                value={selectedOption}
                onChange={(e: any) => {
                  setSelectedOption(e);
                }}
                options={options}
                placeholder="Select User Role"
              />
            </div>
          </div>

          <div className="inputWrapper">
            <PoshInputs
              title="Password"
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              type={'password'}
              readOnly={true}
              validate={'*'}
            />
            <PoshInputs
              title="Confirm Password"
              onChange={(e: any) => {
                setConfirmPassword(e.target.value);
              }}
              type={'password'}
              readOnly={true}
              validate={'*'}
            />
          </div>

          {/* <div className="inputWrapper">
            <ActiveDropdown
            title={"Status"}
              onChange={(e: any) => {
                setStatus(e.target.value);
              }}
            />
          </div> */}

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '7px'}}
          >
            <AppButton
              children={'Cancel'}
              className="btn btn-light mr-3"
              onClick={() => {
                navigate('/admin/user-and-role/users/');
              }}
            />
            <AppButton
              children={'Add'}
              disabled={Disable}
              onClick={() => {
                Addusers();
              }}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default AddUsers;
