import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "src/components/content-header/ContentHeader";



const ExistingInvoice=()=>{
    const [falseData,setFalseData]=useState([
        {id:1,name:"test",status:"2"}
    ])

    const navigate=useNavigate()
    return(
        <div className="container-fluid">
          <ContentHeader title="Existing Incoives" />
        <section className=" bg-white p-4 rounded m-3">
          <div className='d-flex'>
          <div className="table-responsive-xxl" >
        <table className="table table-hover">
          <thead className="thead- bg-primary">
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Flavor Name </th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
             falseData.map((item:any,index:any) => {
              index=index+1
              
          
                
                return (
                 
                  <tr key={item.id} >
                    <td >{index}</td>
                    <td >{item.name}</td>
                    <td style={{ color: item.status === '2'? 'red': 'green'}}>{item.status==1?"Active"  :"In Active" }</td>
                      <td className="iconWapper"  >
                        <img src="/assets/eye.svg" onClick={()=>navigate("/admin/sales-and-invoices/sales/sales/existinginvoice/order")} />
                        {/* <img src={Delete} onClick={()=>modalDelete(item.id)}/> 
                        <Link state={item}  to={"/admin/products/flavors/edit"} >
                        <img src={Edit}  />
                        </Link> */}
                      </td>                 
                  </tr>
                 
                )
              })
          
          }
          </tbody>
        </table>
       
    

      </div>


             </div>
             </section>
        </div>
    )
}



export default ExistingInvoice