import React, {useEffect, useState} from 'react';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import './Addcustomers.css';
import {useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import axios from 'axios';
import {BASE_URL} from '../constants/app';
import {ActiveDropdown} from '../dropdown/ActiveDropdown';
import { progressLoader } from 'src/store/reducers/ui';
function AddCustomers() {
  const navigate = useNavigate();
  const state = useSelector((state: any) => {
    return state.auth?.token;
  });
  const [FirstNameInput, setFirstNameInput]: any = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [Tax, setTax] = useState('');
  const [address, setAddress] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [Zipcode, setZipcode] = useState();
  const [CustomerRefId, setCustomerRefId] = useState();
  const [BillAddress, setBilladdress] = useState('');
  const [Status, setStatus] = useState(1);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const dispatch = useDispatch();

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  const [disabled, setDisabled]: any = useState(true);

  useEffect(() => {
    if (
      FirstNameInput &&
      lastName &&
      address &&
      phone &&
      emailRegex.test(email) &&
      Zipcode &&
      CompanyName &&
      // CustomerRefId &&
      BillAddress &&
      Tax
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    FirstNameInput,
    lastName,
    address,
    phone,
    email,
    Zipcode,
    CompanyName,
    // CustomerRefId,
    BillAddress,
    Tax
  ]);

  const modal = () => {
    if (
      FirstNameInput &&
      lastName &&
      address &&
      phone &&
      emailRegex.test(email) &&
      Zipcode &&
      CompanyName &&
      // CustomerRefId &&
      BillAddress &&
      Tax
    ) {
      swal({
        title: `Are you sure ?`,
        text: ' You want to add customer ?',
        icon: 'warning',
        buttons: [true, true],
        dangerMode: true,
        closeOnClickOutside: false
      }).then((addCustomer) => {
        if (addCustomer) {
          const AddCustomerApi = async () => {
            dispatch(progressLoader(5));
            try {
              axios({
                method: 'post',
                url: `${BASE_URL}customers/add`,
                headers: config,
                data: {
                  custFirstName: FirstNameInput,
                  custLastName: lastName,
                  custaddress: address,
                  custContact: phone,
                  custEmail: email,
                  customer_ref_id: CustomerRefId,
                  companyName: CompanyName,
                  zipcode: Zipcode,
                  status: Status,
                  billAddress: BillAddress,
                  tax: +Tax
                },
                onUploadProgress: (progressEvent) => {
                  let progressB =
                    (progressEvent.loaded / progressEvent.total) * 100;
                  dispatch(progressLoader(progressB));
                }
              }).then((res) => {
                if (res.data.data.error) {
                  swal('Customer Already in the list!', {
                    icon: 'failed'
                  });
                }
                if (res.data.meta.statusCode == 200) {
                  swal({
                    icon: 'success',
                    title:"Success",
                    text:'Customer added successfully.'
                  });
                  setTimeout(() => {
                    navigate('/admin/customers');
                  }, 500);
                }
              });
            } catch (error) {
              swal('Flavour has failed to add !', {
                icon: 'failed'
              });
            }
          };
          AddCustomerApi();
        }
      });
    }
  };

  return (
    <div>
      <ContentHeader title="Add Customer" />
      <section className=" bg-white  p-4 rounded m-3">
        <div>
          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setCompanyName(e.target.value);
              }}
              title="Company Name"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setFirstNameInput(e.target.value);
              }}
              title="First Name"
              type={'text'}
              readOnly={false}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setLastName(e.target.value);
              }}
              title="Last Name"
              type={'text'}
              readOnly={true}
            />

            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              title="Email"
              type={'email'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper ">
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
              title="Phone"
              type={'number'}
              hideControl
              readOnly={true}
            />
            <PoshInputs
              // validate={'*'}
              onChange={(e: any) => {
                setCustomerRefId(e.target.value);
              }}
              title="QuickBooks Ref Id"
              type={'text'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper ">
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setAddress(e.target.value);
              }}
              title="Ship Address"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setBilladdress(e.target.value);
              }}
              title="Bill Address"
              type={'text'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setZipcode(e.target.value);
              }}
              hideControl
              title="Zipcode"
              type={'number'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              onChange={(e: any) => {
                setTax(e.target.value);
              }}
              hideControl
              title="Tax"
              type={'number'}
              readOnly={true}
            />
          </div>
          <div className="inputWrapper">
            <ActiveDropdown
              validate={'*'}
              title="Status"
              onChange={(e: any) => {
                setStatus(e.target.value);
              }}
            />
          </div>

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '8px'}}
          >
            <AppButton
              children={'Cancel'}
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-light mr-3"
            />
            <AppButton
              children={'Save'}
              disabled={disabled}
              onClick={() => {
                modal();
              }}
              className="btn btn-primary"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default AddCustomers;
