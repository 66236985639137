import {useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import './Tableinput.css';
import {useDispatch} from 'react-redux';
import AppButton from '../button/Button';
import exportPDFFlavours from '../ExportPdf&Excel/PdfFlavors';
import {ScaleLoader} from 'react-spinners';
import {downloadExcel} from 'react-export-table-to-excel';
import Select from 'react-select';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';

const FlavorsTable = () => {
  let navigate: any = useNavigate();
  const [Products, setProducts]: any = useState([]);
  const dispatch = useDispatch();
  const [allFlavors, setAllflavors]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption]: any = useState(null);

  const getAll = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'flavors/getAll'
    });
    if (result) {
      setLoading(false);

      const response = result.data;

      const final = response.map((item: any, index: number) => {
        return {
          id: index + 1,
          flavourName: item.flavourName,
          productName: item.productName,
          ShortCode: item.ShortCode,
          Barcode: item.Barcode
        };
      });

      setTimeout(() => {
        console.log({final});
        final &&
          $('#example').DataTable({
            data: final,
            columns: [
              {data: 'id'},
              {data: 'productName'},
              {data: 'flavourName'},
              {data: 'ShortCode'},
              {data: 'Barcode'}
            ]
          });
      }, 1000);

      setAllflavors(
        result?.data?.map((item: any) => {
          return {
            id: item.id,
            flavourName: item.flavourName,
            productName: item.productName,
            ShortCode: item.ShortCode,
            Barcode: item.Barcode
          };
        })
      );
    }
  };

  useEffect(() => {
    if (selectedOption?.label) {
      $('#example').DataTable().destroy();
      SearchApi(selectedOption?.label);
    } else {
      getAll();
      getAllProducts();
    }
  }, [selectedOption]);

  const SearchApi = async (search: string) => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `flavors/search?search=${search}`
    });
    if (result) {
      setLoading(false);
      let response: any = result?.data;
      const final = response.map((item: any, index: number) => {
        return {
          id: index + 1,
          flavourName: item.flavourName,
          productName: item.productName,
          ShortCode: item.ShortCode,
          Barcode: item.Barcode
        };
      });

      setTimeout(() => {
        $('#example').DataTable({
          data: final,
          columns: [
            {data: 'id'},
            {data: 'productName'},
            {data: 'flavourName'},
            {data: 'ShortCode'},
            {data: 'Barcode'}
          ]
        });
      }, 1000);
    }
  };

  const getAllProducts = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'products/getAll'
    });
    if (result) {
      let response = result?.data;

      let finalResponse = response.map((element: any, index: number) => {
        return {label: element.productName, value: element.productName};
      });
      setProducts(finalResponse);
    }
  };

  const onDownload: any = () => {
    let Heading = [
      'S.No.',
      'Product Category',
      'Flavors Name',
      'Short Code',
      'Barcode'
    ];

    const rows: any = allFlavors.map((item: any, index: any) => {
      return [
        index + 1,
        item.productName,
        item.flavourName,
        item.ShortCode,
        item.Barcode
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString('en-GB')} Flavor Table`,
      sheet: `${new Date().toLocaleDateString('en-GB')} Flavor Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  return (
    <div className="container-fluid">
      <section className=" bg-white p-4 rounded m-3">
        <div className="row mb-2 d-flex  col-lg-justify-content-between">
          <div className="col-md-4">
            <Select
              value={selectedOption}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: state.isFocused ? '5px' : '5px'
                })
              }}
              onChange={(e: any) => setSelectedOption(e)}
              options={Products}
              placeholder="Select Product Categories"
            />
          </div>
          <div className="col d-flex justify-content-end  ">
            <div className="btn-group">
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-lg mb-3 mr-3 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export
                </button>
                <div
                  className="dropdown-menu ExportDropdown col-2 dropdownImg"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    onClick={() => {
                      onDownload();
                    }}
                    className="dropdown-item d-flex flex-row"
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/excel.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    Excel
                  </div>

                  <div className="dropdown-divider"></div>
                  <div
                    onClick={() => {
                      exportPDFFlavours(allFlavors);
                    }}
                    className="dropdown-item d-flex flex-row"
                    style={{cursor: 'pointer'}}
                  >
                    <img
                      src="/assets/pdf.png"
                      className="mx-2"
                      style={{
                        height: '30px',
                        width: '30px',
                        margin: 0
                      }}
                    />
                    PDF
                  </div>
                </div>
              </div>
            </div>
            <AppButton
              children={'Manage Flavors'}
              className=" btn-lg mb-3"
              onClick={() => {
                navigate('/admin/products/flavors/add');
              }}
            />
          </div>
        </div>
        {loading ? (
          <div>
            <div className="d-flex justify-content-center">
              <ScaleLoader color="#007bff" height={40} />
            </div>
          </div>
        ) : (
          <table id="example" className="table table-hover">
            <thead className="thead- bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Product Category</th>
                <th scope="col">Flavors Name </th>
                <th scope="col">Short Code</th>
                <th scope="col">Barcode</th>
              </tr>
            </thead>
          </table>
        )}
      </section>
    </div>
  );
};

export default FlavorsTable;
