import React from 'react';
import PerchaseOrder from 'src/components/Tables/PerchaseOrder';

const PurchaseAndOrders = () => {
  return (
    <div className="container-fluid position-sticky" >
      <PerchaseOrder  /> 
    </div>
  );
};

export default PurchaseAndOrders;
