import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './AddFlavours.css';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import AppButton from '../button/Button';
import Table from 'react-bootstrap/Table';
import swal from 'sweetalert';
import {ScaleLoader} from 'react-spinners';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';

const AddFlavours = () => {
  const defaultFlavourData = {
    flavourName: null,
    Barcode: null,
    ShortCode: null,
    product_ref_id: null
  };

  const state = useSelector((state: any) => {
    return state.auth?.token;
  });
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [Products, setProducts]: any = useState('');
  const [price, setPrice] = useState<number>(0);
  const [savedFlavourData, setSavedFlavourData]: any = useState(null);
  const [FlData, setFlData] = useState([defaultFlavourData]);
  const [deletedFlavors, setDeletedFlavors] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [Disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  let navigate: any = useNavigate();

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (
      selectedOption != null &&
      price != null &&
      !FlData.some((obj) => !obj.flavourName)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [price, selectedOption, FlData]);

  const getProducts = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      url: 'products/getActiveProducts'
    });

    if (result) {
      setLoading(false);
      const temp = result.data;
      temp.map((element: any) => {
        return (
          (element['label'] = element['productName']),
          delete element['productName'],
          (element['value'] = element['id'])
        );
      });
      setProducts(temp);
    }
  };

  const options = Products;

  const productById = async (id: number) => {
    setLoading(true);
    const result = await apiResponseGenerator({
      url: `productflavors/getProductFlavors/${id}`,
      dispatchFunction: dispatch
    });

    if (!result) {
      return;
    }
    setLoading(false);

    if (result.data.length > 0) {
      setPrice(result.data[0].price);
      const finalResult = result.data.map((data: any) => {
        const result_data: any = {...data};
        delete result_data.price;
        return result_data;
      });
      setDeletedFlavors([]);
      setFlData(finalResult);
      setSavedFlavourData(finalResult);
    } else {
      setPrice(0);
      setDeletedFlavors([]);
      setFlData([]);
      setSavedFlavourData([]);
    }
  };

  const onChangeFlavor = (e: any, index: any) => {
    const {name, value} = e.target;
    const updated_fl: any = FlData.map((item: any, RowIndex: any) => {
      const objKeys = Object.keys(item);
      return index === RowIndex && objKeys.includes(name)
        ? {...item, [name]: value}
        : item;
    });
    setFlData(updated_fl);
  };

  const Remove = async (Rowindex: number) => {
    const model = await swal({
      title: 'Are you sure ?',
      text: 'You wanna delete this flavor',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      closeOnClickOutside: false
    });
    if (!model) return;

    const newArr = [...FlData];
    newArr.splice(Rowindex, 1);
    const deletedRow: any = FlData.find(
      (data: any, index: number) => index === Rowindex
    );
    // check if deleted flavour is already saved in database
    if (savedFlavourData) {
      const checkIfFlavourExits = savedFlavourData.find(
        (data: any, index: number) => data.id === deletedRow.id
      );
      if (checkIfFlavourExits) {
        const deletedRow_response = {id: deletedRow.id};
        setDeletedFlavors((prevState: any) => [
          ...prevState,
          deletedRow_response
        ]);
      }
    }
    setFlData(newArr);
  };
  const AddRow = () => {
    setFlData((item: any) => [...item, defaultFlavourData]);
  };

  const saveData = async () => {
    const model = await swal({
      title: 'Are you sure ?',
      text: 'You want to Save Data ?',
      icon: 'info',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false
    });

    if (!model) return;

    // validations

    // TODO 1) check price should not be 0
    if(+price <= 0) {
      await swal({
        title: 'validation Error',
        text: "The product price can't be zero",
        icon: 'error',
        closeOnClickOutside: false,
        dangerMode: true
      });

      return
    }

    const generateResponse = {
      product_id: +selectedOption.id || 0,
      price: +price! || 0,
      flavours: FlData.map((data: any) => {
        const auto_ShortCode = returnFirstAndLastValue(data?.flavourName);
        const flavorNewData = {...data};
        delete flavorNewData.Barcode;
        return {
          ...flavorNewData,
          ShortCode:
            data?.ShortCode || data?.ShortCode === ''
              ? data?.ShortCode
              : auto_ShortCode,
          BarCode: data.Barcode
        };
      }),
      del: deletedFlavors
    };

    if (deletedFlavors.length === 0) {
      delete generateResponse.del;
    }

    const duplicateFlavors = generateResponse.flavours.filter(
      (flavor, index) =>
        generateResponse.flavours.findIndex(
          (flavorIndex) =>
            flavor.flavourName.toLowerCase() ===
            flavorIndex.flavourName.toLowerCase()
        ) !== index
    );

    if (duplicateFlavors.length > 0) {
      await swal({
        title: 'Bad Implementation',
        text: `${duplicateFlavors[0].flavourName} already exist`,
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: false
      });
      return;
    }

    await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: 'flavors/add',
      body: generateResponse,
      successMsg: 'Data saved successfully'
    });
  };

  const handleSelect = async (selecteOptionValues: any) => {
    if (selectedOption) {
      const model = await swal({
        title: 'Are your sure?',
        text: 'You want to change the product? your all unsaved data will be lost',
        icon: 'warning',
        buttons: [true, true],
        closeOnClickOutside: false
      });
      if (model) {
        setSelectedOption(selecteOptionValues);
        productById(selecteOptionValues.id || selecteOptionValues.value);
      }
      return;
    }
    setSelectedOption(selecteOptionValues);
    productById(selecteOptionValues.id || selecteOptionValues.value);

    return 'success';
  };

  const returnFirstAndLastValue = (value: any) => {
    const firstLetter = value?.charAt(0)?.toUpperCase();
    const lastLetter = value?.charAt(value?.length - 1)?.toUpperCase();
    return firstLetter || lastLetter ? `${firstLetter}${lastLetter}` : '';
  };

  return (
    <div>
      <ContentHeader title="Manage Flavors" />
      <section className="bg-white p-4 rounded m-3">
        <div className="inputWrapper">
          <div
            className="col-md-4"
            style={{marginLeft: '-7px', marginTop: '-3px'}}
          >
            <label htmlFor="validationCustom03" className="form-label mb-1 ">
              Product Categories
            </label>
            <Select
              value={selectedOption}
              onChange={(e: any) => handleSelect(e)}
              options={options}
              placeholder="Select Product Categories"
            />
          </div>
          <div className="col-md-4">
            <PoshInputs
              value={price === 0 ? '' : price}
              onChange={(e: any) => {
                setPrice(e.target.value);
              }}
              title="Price"
              placeholder={'0'}
              type={'number'}
              min={0}
              hideControl
              readOnly={true}
            />
          </div>
        </div>

        <div>
          {loading ? (
            <div>
              <div
                className="d-flex justify-content-center"
                style={{alignItems: 'center'}}
              >
                <ScaleLoader color="#007bff" height={40} />
              </div>
            </div>
          ) : (
            <>
              <Table className="table table-bordered bg-light my-2">
                <thead className="bg-light">
                  <tr className="text-start" style={{height: 60}}>
                    <th className=" justify-content-center">Flavor Name</th>
                    <th>Shortcode</th>
                    <th>Barcode</th>
                    <th>QuickBooks Ref Id</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {FlData?.map((items: any, index: any) => {
                    // check if the item is exist in FlData array
                    const isExist = FlData.filter(
                      (item: any) => +item.id === +items.id
                    );

                    const auto_ShortCode = returnFirstAndLastValue(
                      items?.flavourName
                    );

                    return (
                      <tr>
                        <td className="bg-white">
                          <input
                            value={items?.flavourName || ''}
                            onChange={(e) => onChangeFlavor(e, index)}
                            type="text"
                            name="flavourName"
                            placeholder="Flavor Name"
                            className={`form-control border-0 main_input`}
                          />
                        </td>

                        <td className="main_Td bg-white">
                          <input
                            name="ShortCode"
                            value={items?.ShortCode ? items?.ShortCode : ''}
                            onChange={(e) => onChangeFlavor(e, index)}
                            type="text"
                            placeholder="Shortcode"
                            className="form-control border-0 bg-transparent main_input"
                          />
                        </td>

                        <td className="main_Td bg-white">
                          <input
                            name="Barcode"
                            type="text"
                            onChange={(e) => onChangeFlavor(e, index)}
                            value={items?.Barcode || ''}
                            placeholder="Barcode"
                            className="form-control border-0 bg-transparent main_input"
                          />
                        </td>

                        <td className="main_Td bg-white">
                          <input
                            name="product_ref_id"
                            type="text"
                            onChange={(e) => onChangeFlavor(e, index)}
                            value={items?.product_ref_id || ''}
                            placeholder="	QuickBooks Ref Id"
                            className="form-control border-0 bg-transparent main_input"
                          />
                        </td>

                        <td className="main_Td bg-white">
                          <button
                            type="button"
                            className="btn btn-dark"
                            // disabled={Dis}
                            onClick={() => Remove(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-dash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="my-3">
                <button
                  onClick={() => AddRow()}
                  type="button"
                  className="btn btn-primary"
                >
                  Add New
                </button>
                <div
                  className="d-flex justify-content-end"
                  style={{marginRight: '4px', marginTop: '-37px'}}
                >
                  <AppButton
                    children={'Go Back'}
                    onClick={() => {
                      navigate(-1);
                    }}
                    type="button"
                    className="btn btn-light mr-3"
                  />
                  <AppButton
                    children={'Save'}
                    disabled={Disable}
                    type="button"
                    onClick={() => saveData()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default AddFlavours;
