import React, {useEffect, useState} from 'react';
import FileInput from '../FileInput/FileInput';
import './AddItems.css';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import {ScaleLoader} from 'react-spinners';
import { ActiveDropdown } from 'src/components/dropdown/ActiveDropdown';
import PoshInputs from 'src/components/Forms/InputForms/Inputs';
import ContentHeader from 'src/components/content-header/ContentHeader';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import AppButton from 'src/components/button/Button';

function AddItems() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  const [loading, setloading]: any = useState<boolean>();
  const [productname, setProductName] = useState(null);
  const [productDes, setProductDes] = useState<any>(null);
  const [status, setStatus]: any = useState(1);
  const [picture, setPicture]: any = useState('');
  const [productSku, setProductSku]: any = useState(null);
  const [ProductVolume, setProductVolume]: any = useState(null);

  const States = {
    productSku,
    productname,
    productDes,
    ProductVolume
  };

  useEffect(() => {
    if (productSku && productname && productDes &&  ProductVolume) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [States]);

  const addProduct = async () => {
    if (productSku && status && productname && productDes) {
      await swal({
        title: 'Are you sure ?',
        text: 'You want to add product category ?',
        icon: 'warning',
        buttons: [true, true],
        dangerMode: true,
        closeOnClickOutside: false,
      }).then(async (res) => {
        if (res) {
          setloading(true);
          let formData = new FormData();
          formData.append('productImage', picture);

          const api_response = await apiResponseGenerator({
            dispatchFunction: dispatch,
            method: 'post',
            url: 'products/upload',
            body: formData
          });
          const imgResponse = api_response;

          const data = {
            productSKU: productSku,
            productName: productname,
            productDescription: productDes,
            status: status,
            productImage: imgResponse,
            volume: ProductVolume
          };

          const add_api_response = await apiResponseGenerator({
            dispatchFunction: dispatch,
            method: 'post',
            url: 'products/add',
            body: data
          });

          if (add_api_response) {
            setloading(false);
            swal({
              icon: 'success',
              title:"Success",
              text:`Product category added successfully.`,
            });
            setTimeout(() => {
              navigate('/admin/products/categories');
            }, 500);
          }
        }
      });
    }
  };

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContentHeader title="Add Product Category" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <PoshInputs
              validate={"*"}
              value={productSku}
              onChange={(e: any) => {
                setProductSku(e.target.value);
              }}
              title="SKU"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={"*"}
              value={productname}
              onChange={(e: any) => {
                setProductName(e.target.value);
              }}
              title="Product Categories"
              type={'text'}
              readOnly={true}
            />
          </div>
          <div className="inputWrapper">
            <PoshInputs
              value={productDes}
              validate={"*"}
              onChange={(e: any) => {
                setProductDes(e.target.value);
              }}
              title="Product Description"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              value={ProductVolume}
              validate={"*"}
              onChange={(e: any) => {
                setProductVolume(e.target.value);
              }}
              title="Volume"
              type={'number'}
              readOnly={true}
              hideControl
            />
          </div>

          <div className="inputWrapper" style={{marginTop: '-28px'}}>
            <ActiveDropdown
              validate={"*"}
              title={'Status'}
              onChange={(e: any) => {
                setStatus(e.target.value);
              }}
            />
            <FileInput
              value={picture}
              onChange={(e: any) => {
                setPicture(e.target.files[0]);
              }}
            />
          </div>

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '13px'}}
          >
            <AppButton
              children={'Cancel'}
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-light mr-3"
            />
            <AppButton
              disabled={disable}
              onClick={() => {
                addProduct();
              }}
              type="submit"
              children={'Save'}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default AddItems;
