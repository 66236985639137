import React from 'react'
import { ContentHeader } from 'src/components'
import IncentiveTable from 'src/components/Tables/IncentiveTable'

function Incentives() {
  return (
    <div>
    <ContentHeader title="Customer Incentives" />
      <IncentiveTable/>
  </div>
  )
}

export default Incentives