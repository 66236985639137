import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ScaleLoader} from 'react-spinners';
import swal from 'sweetalert';
import ContentHeader from '../content-header/ContentHeader';
import { progressLoader, setLoading } from 'src/store/reducers/ui';
import { BASE_URL } from '../constants/app';
import LocalStorageFunctions from 'src/utils/LocalStorageFuntions';
import AppButton from '../button/Button';
import { LocalStorage_Keys } from 'src/enum/LocalStorage_Keys';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';

const CommonTableFlavour = () => {
  const token = useSelector((state: any) => state.auth.token);
  const loading = useSelector((state: any) => state.ui.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const saved_state: any = useLocation();
  const {state}: any = useLocation();
  let saved_state_obj: any = {};
  const localStorageFunctions = new LocalStorageFunctions();
  let invoiceId: string | number = 0;

  const config = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json'
  };

  useEffect(() => {
    VeiwFunction(state);
  }, [state]);

  useEffect(() => {
    saved_state.state?.id && VeiwFunction(saved_state.state?.id);
  }, []);

  const VeiwFunction = (id: number) => {
    dispatch(progressLoader(5));
    dispatch(setLoading(true));
    try {
      axios({
        method: 'get',
        url: `${BASE_URL}sales/getSalesByInvoice/${id}`,
        headers: config,
        onUploadProgress: (progressEvent) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      })
        .then((res: any) => {
          dispatch(setLoading(false));
          if (res.data.meta.statusCode == 200) {
            setdata(res.data.data.data);
          }
        })
        .catch((e: any) => {
          console.log('Veiw api catch', e);
        });
    } catch (e) {
      console.log('veiw api error', e);
    }
  };

  const handelInclude = async () => {
    const invoice_data = JSON.parse(
      localStorage.getItem(LocalStorage_Keys.InvoiceResponse) || '{}'
    );
    const NEW_invoice_data = {
      ...invoice_data,
      invoice_id: invoiceId
    };
    const api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: `invoice/existing`,
      body: NEW_invoice_data
    });
    if (api_response) {
      const model = await swal({
        title: 'Success',
        text: 'Sales added successfully in existing invoice',
        icon: 'success',
        closeOnClickOutside: false,
      });
      if (model) {
        localStorage.removeItem(LocalStorage_Keys.InvoiceResponse);
        navigate('/admin/sales-and-invoices/sales-allocates');
      }
    }else{
      navigate('/admin/sales-and-invoices/sales-allocates')
    }
    return 'success';
  };

  const BackToSales = async () => {
    // const model = await swal({
    //   title: 'Are you sure ?',
    //   text: 'you want to back ? ',
    //   icon: 'warning',
    //   buttons: [true, true]
    // });
    // if (model) {
      localStorage.removeItem(LocalStorage_Keys.InvoiceResponse);
      navigate(-1);
    // }
  };

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContentHeader title="Invoice Details" />
      <section className=" bg-white p-4 rounded m-3">
        <div className="table-responsive-xxl">
          <table className="table table-hover">
            <thead className="thead- bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Products</th>
                <th scope="col">Invoice #</th>
                <th scope="col">Allocation Date</th>
                <th scope="col">Total Cases</th>
                <th scope="col">Total price</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                invoiceId = +item.invoice_id;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.product?.productName}</td>
                    <td>{item.invoice_id}</td>
                    <td>
                      {new Date(item.created_at).toLocaleDateString('en-GB')}
                    </td>
                    <td>{item.total_cases}</td>
                    <td>{item.grand_total}</td>
                    <td className="iconWapper">
                      <Link  to={`/admin/productsinfo/flavor/${+item.invoice_id}`}>
                        <img
                          src="/assets/eye.svg"
                          data-toggle="tooltip"
                          data-html="true"
                          data-bs-placement="top"
                          title="View"
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!saved_state.state.state && (
            <div className="d-flex justify-content-end ">
              <AppButton
                children={'Include'}
                className="mx-3"
                onClick={() => handelInclude()}
              />
              <AppButton
                children={'Back'}
                className="btn btn-light"
                onClick={() => BackToSales()}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CommonTableFlavour;
