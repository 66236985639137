import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import AppButton from '../button/Button';
import {useDispatch, useSelector} from 'react-redux';
import SearchInput from '../Inputs/SearchInput/SearchInput';
import './Tableinput.css';
import {toast} from 'react-toastify';
import {ScaleLoader} from 'react-spinners'; 
import Select from 'react-select';
import apiResponseGenerator from 'src/utils/apiResponseGenerator';
import { setLoading } from 'src/store/reducers/ui';

const IncentiveTable = () => {
  const [customers, setCustomers]: any = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [disbale, setDisable] = useState(false);
  const [Index, setIndex] = useState<number>();
  const loading = useSelector((state: any) => state.ui.loading);
  let local: any = localStorage.getItem('userInfo');
  const permissionsArray = JSON.parse(local).permissions;
  const [Products, setProducts]: any = useState([]);
  const [selectedOption, setSelectedOption]: any = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedOption?.id) {
      getIncentiveById(selectedOption?.id);
    }
    getAllProducts();
  }, [selectedOption]);

  const getAllProducts = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'products/getAll'
    });
    if (result) {
      setLoading(false)
      let response = result?.data;
      let finalResponse = response.map((element: any, index: number) => {
        return {
          id: element.id,
          label: element.productName,
          value: element.productName
        };
      });
      setProducts(finalResponse);
    }
  };

  const getIncentiveById = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `customers/getIncentive/${id}`
    });
    if (result) {
      let response = result?.data;
      setCustomers(response);
    }
  };

  const onChangeIncentive = async (e: any, id: number, index: number) => {
    setIndex(index);
    const {value} = e.target;

    if (parseFloat(value) > 50) {
      toast.error('Please Enter the Incentive Under 50.00');
      return;
    }

    const editData: string[] = customers.map((item: any) => {
      return +item.customer_id === +id
        ? {...item, ['incentive']: +value > 100 ? item?.incentive : value}
        : item;
    });
    setCustomers(editData);
  };

  const onSave = async (item: any, index: number) => {
    if (Index === index) {
      setDisable(true);
    }
    const data = {
      product_id: selectedOption?.id,
      customer_id: +item?.customer_id,
      incentive: +item?.incentive
    };
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: `customers/addProductIncentive`,
      body: data
    });
    if (result) {
      toast.success(`Customer Incentive ${data.incentive} added.`);
      setDisable(false);
    }
  };

  const handleInputChange = (event:any) => {
    const value = event.target.value;
    setSearchValue(value);          
  };

  if (loading) {
    return (
      <div>
        <div
          className="d-flex justify-content-center"
          style={{marginTop: '250px'}}
        >
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <section className="bg-white p-4 rounded m-3">
      <div className='d-flex'>
        <div className="col-md-4 mb-3" style={{marginLeft: '-7px'}}>
          <Select
            value={selectedOption}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                padding: state.isFocused ? '5px' : '5px'
              })
            }}
            onChange={(e: any) => setSelectedOption(e)}
            options={Products}
            placeholder="Select Product Categories"
          />
        </div>
          <div
            id="searchInput"
            className="col-md-4"
          >
            <SearchInput
              placeholder="Search Company Name"
              value={searchValue} onChange={handleInputChange}
            />
          </div>
      </div>
      <div className="table-wrapper-scroll-y my-custom-scrollbar">
        <Table className="table table-bordered bg-light">
          <thead className="bg-primary">
            <tr className="text-start" style={{height: 60}}>
              <th className=" justify-content-center fs-5">Company Name</th>
              <th className="fs-5">Incentive </th>
            </tr>
          </thead>

          <tbody>
            {customers.filter((item:any) => item.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1).map((item: any, index: any) => {
              return (
                <tr key={index}>
                  <td className="bg-white">
                    <input
                      name="Customer Name"
                      disabled={true}
                      value={item.companyName}
                      className="form-control border-0 bg-transparent main_input"
                    />
                  </td>

                  <td
                    className="main_Td bg-white d-flex"
                    style={{border: 'none'}}
                  >
                    <input
                      name="incentive"
                      value={item?.incentive === null ? '' : item?.incentive}
                      min={0}
                      type="number"
                      onChange={(e) =>
                        onChangeIncentive(e, +item?.customer_id, index)
                      }
                      placeholder="0"
                      className="form-control border-0 bg-transparent main_input hideNumber_controls"
                    />
                    {permissionsArray.map((data: any) => {
                      return data.title === 'Incentives' &&
                        data.edit === true ? (
                        <AppButton
                          children={'Save'}
                          disabled={index === Index ? disbale : false}
                          onClick={() => onSave(item, index)}
                        />
                      ) : (
                        ' '
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <br />
        <br />
        <br />
      </div>
    </section>
  );
};

export default IncentiveTable;
