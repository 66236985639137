import React from 'react';
import ReceivingTable from './ReceivingTable';
import ContentHeader from '../content-header/ContentHeader';


const PerchaseOrder = () => {   
  return (
    <>       
        <ContentHeader title="Purchase Order" />
        <section className=" bg-white p-4 rounded m-3">
          <ReceivingTable/>
          {/* <TableDesign onClick={props.onClick}  title='testing'placeHolder={"string"} color={"table-secondary"} readOnly={true} /> */}
        </section>
    </>
  );
};

export default PerchaseOrder;