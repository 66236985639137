
import React from 'react';
import Flavor from 'src/components/Flavors/Flavors';
import { ContentHeader } from 'src/components';

const Flavors = () => {
  return (
    <div>
      <ContentHeader title="Flavors" />
        <Flavor/> 
    </div>
  );
};

export default Flavors;
