import React from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Link, useNavigate} from 'react-router-dom';
import "./ConfirmPassword.css"
import "@fontsource/poppins";
import LoginInput from 'src/components/Inputs/LoginInputs/LoginInput';
import AppButton from 'src/components/button/Button';
import { setWindowClass } from 'src/utils/helpers';

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required')
    }),
    onSubmit: (values) => {
    
      console.log(values);
      toast.warn('Not yet functional');
    }
  });

  setWindowClass('hold-transition login-page');

  return (
          <>
            <div className="container-fluid bgImg">
              <div className="login-box">
                <div className="card" style={{width:"100%"}}>
                  <div className="wrapper center">
                  <img src="/assets/logo.svg" />
                    <p className="fw-bolder text-center mt-1 logoText" >Sales & inventory Management</p> 
                  </div>
                  <div className="card-body">
                      <form  style={{maxWidth: '450px', margin: 'auto'}}>
                        <LoginInput  
                          label={"New Password"} 
                          placeholder={"Enter Your New Password"} 
                          icon={"fa fa-key icon"} 
                          type={"password"} 
                        />

                        <LoginInput  
                          label={"Comfirm Password"} 
                          placeholder={"Enter Your Comfirm Password"} 
                          icon={"fa fa-key icon"} 
                          type={"password"} 
                        />

                          <AppButton children={"Submit"} className='btn btn-dark col-12 forgotbtn' style={{backgroundColor:"black"}} />
                            <span>
                                <h6 className="d-flex justify-content-center align-top text-muted text-sm mt-2 ">Designed & Developed by</h6>
                                <b className="d-flex justify-content-center align-bottom text-sm fs-5">Zetsol Technologics Pvt Ltd.</b>
                            </span>                 

                      </form> 
                  </div>
                </div>
              </div>
            </div>
          </>
  );
};

export default ConfirmPassword;
