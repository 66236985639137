import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../Flavors/AddFlavours.css';
import {useDispatch, useSelector} from 'react-redux';
// import {progressLoader} from '@app/store/reducers/ui';
import Select from 'react-select';
import AppButton from '../button/Button';
import Table from 'react-bootstrap/Table';
import swal from 'sweetalert';
import {ScaleLoader} from 'react-spinners';
import IncentiveModal from '../Modal/Model';
import { toast } from 'react-toastify';
import { BASE_URL } from '../constants/app';
import { progressLoader } from 'src/store/reducers/ui';

function ReceivingTable(props: any) {
  const default_FlData = [
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    },
    {
      flavourCategory: null,
      flavour: null,
      BarCode: null,
      currentStock: null,
      quantity: null
    }
  ];

  const state = useSelector((state: any) => {
    return state.auth?.token;
  });
  const [modelShow, setModelShow] = useState<any>(false);
  const [Products, setProducts]: any = useState('');
  const [Disable, setDisable] = useState(true);
  const [FlData, setFlData] = useState(default_FlData);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [GenerateBtnDisable, setGenerateBtnDisable] = useState(false);
  let navigate: any = useNavigate();

  // hardCoded Values
  const flavourFldName = {
    flavourCategory: 'flavourCategory',
    flavour: 'flavour'
  };

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${state}`
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      dispatch(progressLoader(15));
      const res = await axios({
        url: `${BASE_URL}products/getAll`,
        headers: config,
        timeout: 1000 * 5,
        onUploadProgress: (progressEvent) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        setLoading(false);
        const temp = res.data.data;
        temp.map((element: any) => {
          return (
            (element['label'] = element['productName']),
            delete element['productName'],
            (element['value'] = element['id'])
          );
        });
        setProducts(temp);
      }
    } catch (error) {
      console.log(error);
      dispatch(progressLoader(100));
      setLoading(true);
    }
  };

  const CheckSameDataExits = (
    // fldNameOne: string,
    fldName: string,
    selectedOption: any
    // fldNameTwo?: string
  ) => {
    let FlData_copied: any[] = FlData;
    let isExits: boolean = false;

    if (FlData?.length > 1) {
      FlData_copied.forEach((element: any) => {
        if (+element[fldName]?.id === +selectedOption?.id) {
          isExits = true;
        }
      });

      if (isExits) {
        swal({
          title: 'Error',
          text: `${selectedOption.label} already exists`,
          icon: 'warning',
          buttons: [false, true],
          dangerMode: true,
          closeOnClickOutside: false,
        });
      }
    }

    return isExits;
  };

  const handleChange = async (name: string, selectFlavor: any, index: any) => {
    let setValue: any = {
      id: null,
      value: null,
      label: null
    };
    let checkStock: any;
    let flavorsOptions: any;

    if (name === flavourFldName.flavourCategory) {
      // const checkData = CheckSameDataExits(
      //   flavourFldName.flavourCategory,
      //   selectFlavor
      // );
      // if (checkData) return;
      flavorsOptions = await getFlavorOptions(selectFlavor.id, index);
    }

    if (name === flavourFldName.flavour) {
      const checkData = CheckSameDataExits(
        flavourFldName.flavour,
        selectFlavor
      );
      if (checkData) return;
      checkStock = await getStock(+selectFlavor.id);
    }

    setValue = {
      id: +selectFlavor.id,
      value: +selectFlavor.value,
      label: selectFlavor.label
    };

    const updated_fl: any = FlData.map((item: any, RowIndex: number) => {
      const objKeys = Object.keys(item);
      const condition = index === RowIndex && objKeys.includes(name);
      let returnValue = null;

      if (condition) {
        returnValue = checkStock
          ? {
              ...item,
              [name]: setValue,
              BarCode: checkStock.BarCode,
              currentStock: checkStock.currentStock
            }
          : {...item, flavorsOptions: flavorsOptions, [name]: setValue};
      } else {
        returnValue = item;
      }
      if (returnValue) return returnValue;
    });
    setFlData(updated_fl);

    return 'success';
  };

  const getFlavorOptions = async (id: number, RowIndex?: number) => {
    if (!id) {
      return;
    }
    try {
      const result = await axios({
        method: 'get',
        url: `${BASE_URL}productflavors/getFlavor/${+id}`,
        headers: config
      });
      if (result) {
        const temp = result.data.data;
        const flavorOptions = temp.map((element: any) => {
          return {
            id: +element.id,
            value: +element.id,
            label: element.FlavorName
          };
        });
        return flavorOptions;
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getStock = async (id: number) => {
    let obj = {};
    if (!id) {
      return;
    }
    try {
      const result: any = await axios({
        method: 'get',
        url: `${BASE_URL}productflavors/getFlavorStock/${+id}`,
        headers: config
      });
      if (result) {
        const res = result.data.data;
        // res.forEach((item: any) => {
          obj = {
            BarCode: res.Barcode,
            currentStock: res.current_stock
          };
        // });
      }
      return obj;
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeFlavor = (e: any, index: any) => {
    const {name, value} = e.target;
    const valueToNumber = value;
    // const valueToNumber = value === "." ? value : +value;
    const updated_fl: any = FlData.map((item: any, RowIndex: any) => {
      const objKeys = Object.keys(item);
      return index === RowIndex && objKeys.includes(name)
        ? {...item, [name]: valueToNumber}
        : item;
    });
    setFlData(updated_fl);
  };

  const Remove = async (index: any) => {
    const newArr = [...FlData];
    newArr.splice(index, 1);
    setFlData(newArr);
  };

  const shipments = props;

  const HandleBackValidator = async () => {
    FlData.some(async (data:any)=>{
      if (data.flavourCategory && data.flavour || shipments.airwayNo || shipments.lotNo || shipments.weight || shipments.expectedDate || shipments.dispatchedDate   ) {
        const model = await swal({
          title: 'Are you sure ?',
          text: 'All inserted data will be lost.',
          buttons: ['Cancel', 'Yes'],
          icon: 'warning'
        });
        if (!model) return;
        navigate(-1)
      } else {
        navigate(-1)
      }
    })
  };

  const AddRow = () => {
    setFlData((item: any) => [
      ...item,
      {
        flavourCategory: null,
        flavour: null,
        BarCode: null,
        currentStock: null,
        quantity: null
      }
    ]);
  };



  const AddShipments = async () => {
    try {
      dispatch(progressLoader(15));
      setGenerateBtnDisable(true);
      const res = await axios({
        url: `${BASE_URL}purchaseOrder/add`,
        headers: config,
        method: 'post',
        data: {
          shipments,
          flavors: FlData.map((item: any) => {
            return {
              product_flavour_id: item.flavour.id,
              qtyOrdered: +item.quantity
            };
          })
        },
        onUploadProgress: (progressEvent) => {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        },
        onDownloadProgress(progressEvent: {loaded: number; total: number}) {
          let progressB = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(progressLoader(progressB));
        }
      });
      if (res.data.meta.statusCode == 200) {
        const model = await swal({
          title:"Success",
          text:'Purchase Order Created Successfully.',
          icon: 'success'
        });
        if (model) {
          navigate('/admin/purchase-and-orders/shipment-receivings');
        }
      }
    } catch (error:any) {
      const model:any = swal({
        title: 'Error',
        text:`${error.response.data.data.message}`,
        icon: 'error'
      });
      console.log(typeof model)
      if(!model){
        navigate(0)
      }
    }
  };

  const CheckProps = {
    shipping_Agent_id: props.shipping_Agent_id || null,
    airwayNo: props?.airwayNo || '',
    weight: props?.weight || '',
    lotNo: props?.lotNo || '',
    expectedDate: props?.expectedDate || '',
    dispatchedDate: props?.dispatchedDate || '',
  };

  const HandleValidator = () => {
    if(!CheckProps.lotNo){
      toast.error("Lot No. required")
      return
    }
    if(CheckProps.airwayNo === ''){
      toast.error("Airway Bill No. required")
      return
    }
    if(CheckProps.weight === ''){
      toast.error("Weight required")
      return
    }
    if(!CheckProps.shipping_Agent_id){
      toast.error("Shipping agent required")
      return
    }
    if(CheckProps.dispatchedDate === ''){
      toast.error("Dispatched date required")
      return
    }
    if(CheckProps.expectedDate === ''){
      toast.error("Expected delivery date required")
      return
    }
    setModelShow(true)
  }

  useEffect(() => {
    FlData.some((data:any)=>{
      if (data.flavourCategory && data.flavour && data.quantity>0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    })
  }, [FlData]);


  const options = Products;

  return loading ? (
    <div>
      <div className="d-flex justify-content-center">
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <div>
      <div className="table-responsive-xxl" style={{overflowX: "clip"}}>
        <Table className="table table-bordered bg-light my-2">
          <thead className="bg-light">
            <tr className="text-start" style={{height: 60}}>
              <th>Product Category</th>
              <th>Flavor</th>
              <th>Barcode</th>
              <th>Current Stock</th>
              <th>Quantity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {FlData?.map((items: any, index: number) => {
              return (
                <tr key={index}>
                  <td
                    className=" bg-white"
                    style={{height: '60px', width: '250px'}}
                  >
                    <Select
                      value={items.flavourCategory}
                      name="flavourCategory"
                      onChange={(e: any) =>
                        handleChange(flavourFldName.flavourCategory, e, index)
                      }
                      options={options}
                      placeholder="Select Product Category"
                    />
                  </td>

                  <td
                    className=" bg-white"
                    style={{height: '60px', width: '220px'}}
                  >
                    <Select
                      value={items.flavour}
                      name="flavour"
                      isDisabled={
                        items.flavorsOptions?.length >= 0 ||
                        items.flavorsOptions
                          ? false
                          : true
                      }
                      onChange={(e: any) =>
                        handleChange(flavourFldName.flavour, e, index)
                      }
                      options={items.flavorsOptions}
                      placeholder="Select Flavor"
                    />
                  </td>

                  <td
                    className=" bg-white"
                    style={{height: '60px', width: '120px'}}
                  >
                    <input
                      value={items?.BarCode ? items.BarCode : 0}
                      disabled={true}
                      placeholder="Barcode"
                      className="form-control border-0 bg-transparent main_input"
                    />
                  </td>

                  <td
                    className="main_Td bg-white"
                    style={{
                      height: '60px',
                      textAlign: 'center',
                      width: '120px'
                    }}
                  >
                    <input
                      value={items?.currentStock ? items?.currentStock : 0}
                      disabled={true}
                      placeholder="Current Stock"
                      className="form-control border-0 bg-transparent main_input"
                    />
                  </td>

                  <td
                    className="main_Td bg-white"
                    style={{
                      height: '60px',
                      textAlign: 'center',
                      width: '120px'
                    }}
                  >
                    <input
                      name="quantity"
                      min={0}
                      required
                      type="number"
                      value={items.quantity}    
                      onChange={(e: any) => onChangeFlavor(e, index)}
                      placeholder="0"
                      className="form-control border-0 bg-transparent main_input hideNumber_controls"
                    />
                  </td>

                  <td
                    className="main_Td bg-white"
                    style={{height: '60px', width: '100px'}}
                  >
                    <button
                      type="button"
                      className="btn btn-dark"
                      // disabled={Dis}
                      onClick={() => {
                        Remove(index);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-dash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="my-3">
        <button
          onClick={() => {
            AddRow();
          }}
          type="button"
          className="btn btn-primary"
        >
          Add New
        </button>
        <div
          className="d-flex justify-content-end"
          style={{marginRight: '4px', marginTop: '-37px'}}
        >
          <div>
            <AppButton
              children={'Go Back'}
              onClick={() => {
                HandleBackValidator()
              }}
              type="button"
              className="btn btn-light mr-3"
            />
          </div>
          <div>
            <button
              className="btn btn-primary"
              disabled={Disable}
              onClick={() => HandleValidator()}
              type="button"
            >
              Verify & Generate
            </button>
            <IncentiveModal
              title={'Confirm Order'}
              data={FlData}
              show={modelShow}
              GenerateBtnDisable={GenerateBtnDisable}
              onHide={() => setModelShow(false)}
              AddShipments={() => AddShipments()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceivingTable;
